// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-post-jsx": () => import("/opt/build/repo/src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-tag-jsx": () => import("/opt/build/repo/src/templates/tag.jsx" /* webpackChunkName: "component---src-templates-tag-jsx" */),
  "component---src-templates-category-jsx": () => import("/opt/build/repo/src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-pages-change-password-jsx": () => import("/opt/build/repo/src/pages/change-password.jsx" /* webpackChunkName: "component---src-pages-change-password-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("/opt/build/repo/src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-demo-jsx": () => import("/opt/build/repo/src/pages/demo.jsx" /* webpackChunkName: "component---src-pages-demo-jsx" */),
  "component---src-pages-faqs-jsx": () => import("/opt/build/repo/src/pages/faqs.jsx" /* webpackChunkName: "component---src-pages-faqs-jsx" */),
  "component---src-pages-forgot-password-jsx": () => import("/opt/build/repo/src/pages/forgot-password.jsx" /* webpackChunkName: "component---src-pages-forgot-password-jsx" */),
  "component---src-pages-identification-documents-received-jsx": () => import("/opt/build/repo/src/pages/identification-documents-received.jsx" /* webpackChunkName: "component---src-pages-identification-documents-received-jsx" */),
  "component---src-pages-identification-failed-jsx": () => import("/opt/build/repo/src/pages/identification-failed.jsx" /* webpackChunkName: "component---src-pages-identification-failed-jsx" */),
  "component---src-pages-identity-verified-jsx": () => import("/opt/build/repo/src/pages/identity-verified.jsx" /* webpackChunkName: "component---src-pages-identity-verified-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("/opt/build/repo/src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-on-boarding-business-type-jsx": () => import("/opt/build/repo/src/pages/on-boarding-business-type.jsx" /* webpackChunkName: "component---src-pages-on-boarding-business-type-jsx" */),
  "component---src-pages-on-boarding-c-corp-personal-verification-jsx": () => import("/opt/build/repo/src/pages/on-boarding-c-corp-personal-verification.jsx" /* webpackChunkName: "component---src-pages-on-boarding-c-corp-personal-verification-jsx" */),
  "component---src-pages-on-boarding-sole-prop-business-verification-jsx": () => import("/opt/build/repo/src/pages/on-boarding-sole-prop-business-verification.jsx" /* webpackChunkName: "component---src-pages-on-boarding-sole-prop-business-verification-jsx" */),
  "component---src-pages-on-boarding-sole-prop-personal-verification-jsx": () => import("/opt/build/repo/src/pages/on-boarding-sole-prop-personal-verification.jsx" /* webpackChunkName: "component---src-pages-on-boarding-sole-prop-personal-verification-jsx" */),
  "component---src-pages-password-updated-jsx": () => import("/opt/build/repo/src/pages/password-updated.jsx" /* webpackChunkName: "component---src-pages-password-updated-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("/opt/build/repo/src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-set-password-jsx": () => import("/opt/build/repo/src/pages/set-password.jsx" /* webpackChunkName: "component---src-pages-set-password-jsx" */),
  "component---src-pages-signup-jsx": () => import("/opt/build/repo/src/pages/signup.jsx" /* webpackChunkName: "component---src-pages-signup-jsx" */),
  "component---src-pages-terms-of-use-jsx": () => import("/opt/build/repo/src/pages/terms-of-use.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-jsx" */),
  "component---src-pages-theme-jsx": () => import("/opt/build/repo/src/pages/theme.jsx" /* webpackChunkName: "component---src-pages-theme-jsx" */),
  "component---src-pages-verify-account-jsx": () => import("/opt/build/repo/src/pages/verify-account.jsx" /* webpackChunkName: "component---src-pages-verify-account-jsx" */),
  "component---src-pages-wellness-welcome-jsx": () => import("/opt/build/repo/src/pages/wellness-welcome.jsx" /* webpackChunkName: "component---src-pages-wellness-welcome-jsx" */),
  "component---src-pages-workforce-jsx": () => import("/opt/build/repo/src/pages/workforce.jsx" /* webpackChunkName: "component---src-pages-workforce-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

